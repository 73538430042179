import React, { forwardRef } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  styled,
} from '@mui/material';
import clsx from 'clsx';
import { useAppProvider } from '../../providers/app/app';
import { applyOpacityOnColor } from '../../utils/color';
import { PaletteTypeButtonProps } from '../../types/app';

export const M3ButtonBase = styled(Button)`
  border: 0;
  height: 40px;
  border-radius: 32px;
  padding-left: 12px;
  padding-right: 12px;
  transition: none;
  font-size: 14px;
  font-weight: 500;
  text-transform: initial;
  color: var(--md-sys-color-on-surface-light);
  white-space: nowrap;

  .MuiSvgIcon-root {
    font-size: 22px;
    margin-right: 8px;
  }

  .m3-button-outlined-background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    border-radius: 40px;
    pointer-events: none;
  }

  &.active,
  &:hover {
    background-color: var(--md-sys-color-surface-variant-light);
  }

  &.MuiButton-containedPrimary:not(.Mui-disabled) {
    color: var(--md-ref-palette-primary100);
    background-color: var(--md-ref-palette-primary40);
  }

  &.MuiButton-containedSecondary:not(.Mui-disabled) {
    color: var(--md-ref-palette-secondary100);
    background-color: var(--md-ref-palette-secondary40);
  }

  &.MuiButton-contained {
    &:hover {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
  }

  &.MuiButton-outlinedPrimary,
  &.MuiButton-outlinedSecondary {
    border: 0;
    box-shadow: 0 0 0 1px var(--md-ref-palette-neutral-variant80);

    &:hover {
      border: 0;
      background: transparent !important;

      .m3-button-outlined-background {
        opacity: 0.08;
      }
    }
  }

  &.dark-mode-button {
    color: var(--md-sys-color-on-background-dark);

    .MuiSvgIcon-root {
      color: var(--md-sys-color-on-background-dark);
    }

    &.active,
    &:hover {
      background-color: var(--md-sys-color-surface-variant-dark);
    }

    &.MuiButton-containedPrimary,
    &.MuiButton-containedSecondary {
      .MuiSvgIcon-root {
        color: var(--md-sys-color-background-dark);
      }
    }

    &.MuiButton-containedPrimary:not(.Mui-disabled) {
      color: var(--md-ref-palette-primary0);
      background-color: var(--md-ref-palette-primary80);
    }

    &.MuiButton-containedSecondary:not(.Mui-disabled) {
      color: var(--md-ref-palette-secondary0);
      background-color: var(--md-ref-palette-secondary20);
    }

    &.MuiButton-contained {
      &:hover {
        box-shadow: 0px 2px 1px -1px rgba(255, 255, 255, 0.2),
          0px 1px 1px 0px rgba(255, 255, 255, 0.14),
          0px 1px 3px 0px rgba(255, 255, 255, 0.12);
      }
    }

    &.MuiButton-outlinedPrimary,
    &.MuiButton-outlinedSecondary {
      box-shadow: 0 0 0 1px var(--md-ref-palette-neutral-variant30);
    }
  }
`;

export const M3ButtonTonedBase = styled(M3ButtonBase)`
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 2px transparent;
  color: var(--md-ref-palette-secondary10);
  background-color: var(--md-ref-palette-secondary90);

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      var(--md-ref-palette-secondary90);
  }

  &.dark-mode-button {
    &.MuiButton-text {
      color: var(--md-ref-palette-secondary90);
      background-color: var(--md-ref-palette-secondary20);
    }

    &:hover {
      &.MuiButton-text .MuiSvgIcon-root,
      &.MuiButton-text {
        color: var(--md-ref-palette-secondary90);
      }

      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0.04)
        ),
        var(--md-ref-palette-secondary10);
    }
  }
`;

type M3ButtonTonedProps = ButtonProps &
  PaletteTypeButtonProps & {
    active?: boolean;
  };
export const M3ButtonToned = ({
  palette: paletteKey = 'main',
  active,
  className,
  ...restProps
}: M3ButtonTonedProps) => {
  const { isDarkMode } = useAppProvider();

  return (
    <M3ButtonTonedBase
      className={clsx(
        {
          active,
          'dark-mode-button': isDarkMode,
        },
        className,
      )}
      {...restProps}
    />
  );
};

type M3ButtonProps = ButtonProps &
  PaletteTypeButtonProps & {
    active?: boolean;
  };
export const M3Button = ({
  color,
  disabled,
  variant,
  sx,
  palette: paletteKey = 'main',
  active,
  className,
  children,
  ...restProps
}: M3ButtonProps) => {
  const {
    palettes: { [paletteKey]: palette },
    isDarkMode,
  } = useAppProvider();
  const hasColorAndDisabled = !!color && disabled;

  if (variant === 'outlined') {
    let background: string = '';
    if (color === 'primary') {
      background = isDarkMode
        ? 'var(--md-ref-palette-primary80)'
        : 'var(--md-ref-palette-primary40)';
    } else if (color === 'secondary') {
      background = isDarkMode
        ? 'var(--md-ref-palette-secondary80)'
        : 'var(--md-ref-palette-secondary40)';
    }
    children = (
      <>
        <Box
          component='span'
          sx={{ background }}
          className='m3-button-outlined-background'
        />
        <Box
          flex={1}
          height='100%'
          display='flex'
          component='div'
          position='relative'
          alignItems='center'
          justifyContent='center'
        >
          {children}
        </Box>
      </>
    );
  }

  return (
    <M3ButtonBase
      color={color}
      disabled={disabled}
      variant={variant}
      sx={{
        '&.Mui-disabled,&.Mui-disabled': {
          color: `${applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.neutral90']
              : palette['md.ref.palette.neutral10'],
            0.38,
          )} !important`,
          backgroundColor:
            variant === 'outlined'
              ? 'transparent !important'
              : hasColorAndDisabled
              ? `${applyOpacityOnColor(
                  isDarkMode
                    ? palette['md.ref.palette.neutral90']
                    : palette['md.ref.palette.neutral10'],
                  0.12,
                )} !important`
              : undefined,
          ...(isDarkMode
            ? {
                '.MuiSvgIcon-root': {
                  opacity: 0.3,
                  color: 'var(--md-sys-color-on-background-dark) !important',
                },
              }
            : null),
        },
        ...sx,
      }}
      className={clsx(
        {
          active,
          'dark-mode-button': isDarkMode,
        },
        className,
      )}
      {...restProps}
    >
      {children}
    </M3ButtonBase>
  );
};

export const M3IconButtonBase = styled(IconButton)`
  width: 40px;
  height: 40px;
  color: var(--md-sys-color-on-surface-light);

  &.active,
  &:hover {
    background-color: var(--md-sys-color-surface-variant-light);
  }

  &.dark-mode-button {
    color: var(--md-sys-color-on-surface-dark);

    &.active,
    &:hover {
      background-color: var(--md-sys-color-surface-variant-dark);
    }
  }

  &.m3-icon-button-back-detail-button {
    margin-left: -16px;
  }
`;

type M3IconButtonProps = IconButtonProps & { active?: boolean };
export const M3IconButton = forwardRef(
  ({ className, active, sx, ...restProps }: M3IconButtonProps, ref) => {
    const { isDarkMode } = useAppProvider();

    return (
      <M3IconButtonBase
        {...restProps}
        className={clsx(
          {
            active,
            'dark-mode-button': isDarkMode,
          },
          className,
        )}
        sx={{
          '&.Mui-disabled': {
            ...(isDarkMode
              ? {
                  '.MuiSvgIcon-root': {
                    opacity: 0.3,
                    color: 'var(--md-sys-color-on-background-dark) !important',
                  },
                }
              : null),
          },
          ...sx,
        }}
      />
    );
  },
);
